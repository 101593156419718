import React from 'react';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { DocumentCheckOutlineIcon, ArrowRightOutlineIcon, ArrowLeftOutlineIcon } from '@applyboard/ui-icons';
import {
  Dialog,
  Button,
  Flex,
  Text,
  ButtonLink,
  CheckboxGroup,
  Spinner,
  TextInput,
  Textarea,
} from '@applyboard/crystal-ui';

import { useApplicationFinalOfferCondition } from '../../../data/useFinalOfferConditions';
import { useIssueFinalOfferActivity } from './useIssueFinalOfferActivity';
import { useDeferralRequestActivity } from '../DeferralRequest/useDeferralRequestActivity';
import { useNotice } from '../useNotice';
import { DialogHeader, STEPS } from '../Decline/DialogHeading';

export function FinalOfferDialog({
  applicationId,
  patchDeferralRequest,
}: {
  applicationId: string;
  patchDeferralRequest?: boolean;
}) {
  const { apiError } = useErrorHandler();
  const { completeProcess, application, startProcess } = useAppProcessing();
  const [loading, setLoading] = React.useState(false);
  const [downloadUrl, setDownloadUrl] = React.useState('');
  const [conditions, setConditions] = React.useState<Array<string>>(
    application.applicationAdmissionConditions.filter((c) => c.code !== 'other').map((c) => c.code)
  );
  const [customCondition, setCustomCondition] = React.useState(
    application.applicationAdmissionConditions.find((c) => c.code === 'other')?.name || ''
  );
  const [additionalNotes, setAdditionalNotes] = React.useState('');
  const [stepState, setStepState] = React.useState<number>(STEPS.STEP1);
  const activity = useIssueFinalOfferActivity(applicationId);
  const deferralRequestActivity = useDeferralRequestActivity();
  const { setNotice } = useNotice();

  function onOpenChange(open: boolean) {
    if (!open) {
      setStepState(STEPS.STEP1);
      setCustomCondition('');
      setConditions([]);
      completeProcess();
      setAdditionalNotes('');
    }
  }

  async function onSubmit() {
    setLoading(true);
    if (patchDeferralRequest)
      deferralRequestActivity
        .processDeferralRequest({
          accept: true,
          conditions,
          customCondition,
          additionalNotes: additionalNotes ? additionalNotes : undefined,
        })
        .then(() => {
          setNotice(
            'The application intake has been updated and a new final offer letter has been issued.',
            'positive'
          );
          deferralRequestActivity.completeProcess();
        })
        .catch(() => apiError())
        .finally(() => setLoading(false));
    else
      activity
        .issueFinalOffer(conditions, customCondition, additionalNotes ? additionalNotes : undefined)
        .then(() => {
          completeProcess();
          setNotice('Successfully sent final offer letter. It will be available here shortly.', 'positive');
        })
        .catch(() => apiError())
        .finally(() => setLoading(false));
  }

  async function onButtonClick1() {
    setLoading(true);
    activity
      .generateFinalOffer({
        conditions,
        customCondition,
        deferralRequestId: patchDeferralRequest ? application.meta.deferralRequest!.id : undefined,
        additionalNotes: additionalNotes ? additionalNotes : undefined,
      })
      .then(({ letterPreviewDownloadUrl }) => {
        setDownloadUrl(letterPreviewDownloadUrl);
        setStepState(STEPS.STEP2);
      })
      .catch(() => apiError())
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Dialog onOpenChange={onOpenChange} size="lg" open={true}>
      <Dialog.Heading>
        <Flex direction="row" gap={2}>
          {patchDeferralRequest && stepState === STEPS.STEP1 && (
            <Button
              aria-label="backButton"
              intent="secondary"
              emphasis="highlighted"
              leadIcon={ArrowLeftOutlineIcon}
              onClick={() => startProcess('deferralRequest')}
            />
          )}
          <DialogHeader setStepState={setStepState} stepState={stepState} heading="Issue Final offer" />
        </Flex>
      </Dialog.Heading>
      <Dialog.Content>
        <Flex direction="column" gap={8}>
          {stepState === STEPS.STEP1 && (
            <FinalOfferConditionGroup
              conditions={conditions}
              setConditions={setConditions}
              customCondition={customCondition}
              setCustomCondition={setCustomCondition}
              setAdditionalNotes={setAdditionalNotes}
              additionalNotes={additionalNotes}
            />
          )}
          {stepState === STEPS.STEP2 && (
            <>
              <ButtonLink
                aria-label="Download Final offer LOA"
                emphasis="highlighted"
                href={downloadUrl}
                intent="primary"
                size="lg"
                leadIcon={DocumentCheckOutlineIcon}
                width="fill"
                mode="override_user_choice_and_open_in_new_tab"
              >
                final-offer LOA
              </ButtonLink>
              <Text variant="bodyM" intent="secondary">
                A preview of the final offer letter is available before sending it to the applicant. Once the letter is
                sent, you will not be able to make changes.
              </Text>
            </>
          )}
        </Flex>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton disabled={loading} width="fill">
          Cancel
        </Dialog.CloseButton>
        {stepState === STEPS.STEP1 && (
          <Button
            intent="primary"
            loading={loading}
            width="fill"
            trailIcon={ArrowRightOutlineIcon}
            onClick={onButtonClick1}
          >
            Next
          </Button>
        )}
        {stepState === STEPS.STEP2 && (
          <Button intent="primary" loading={loading} onClick={onSubmit} width="fill">
            Send Final Offer
          </Button>
        )}
      </Dialog.Actions>
    </Dialog>
  );
}

function FinalOfferConditionGroup(props: {
  setConditions: (conditions: Array<string>) => void;
  conditions: Array<string>;
  setCustomCondition: (condition: string) => void;
  customCondition: string;
  setAdditionalNotes: (notes: string) => void;
  additionalNotes: string;
}) {
  const { finalOfferConditions, isFetchingFinalOfferCondition } = useApplicationFinalOfferCondition();

  if (isFetchingFinalOfferCondition) {
    return (
      <Flex direction="column" align="center">
        <Flex>
          <Spinner />
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <CheckboxGroup
        onChange={(conditions: Array<string>) => {
          props.setConditions(conditions);
        }}
        value={props.conditions}
        label="You can optionally choose one or more conditions so the student can be accepted in the program."
      >
        <Flex direction="column">
          {finalOfferConditions?.map((i) => <CheckboxGroup.Option label={i.name} value={i.code} key={i.id} />)}
        </Flex>
      </CheckboxGroup>
      <TextInput
        label="Custom condition"
        intent="secondary"
        onChange={(condition: string) => props.setCustomCondition(condition)}
        value={props.customCondition}
        helpText="Add any conditions that are not included in the list above"
      />
      <Textarea
        label="Additional Notes"
        intent="secondary"
        onChange={(notes: string) => props.setAdditionalNotes(notes)}
        value={props.additionalNotes}
      />
    </>
  );
}
